import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import logo from './logo.svg';
import layer2 from './layer2.svg';
import './App.css';
import useKonamiCode from "./is-konami-code.js";

function App(props) {
  const [konamiCode, setIsKonami] = useKonamiCode();
  const [nextNumberwang, setNextNumberwang] = useState("");

  let spinClass = konamiCode ? "App-logo-nospin" : "App-logo";
  let welcomeText = konamiCode ? "Welcome." : "Off by One."
  let contactText = konamiCode ? "Would you like to play a game?" : "ad astra per aspera";
  let hrefString = konamiCode ? "/numberwang/42" : "mailto:hello@offx1.com";

  function getNextNumberwang() {
    let randomNumberwang = `/numberwang/${Math.floor(Math.random()*9007199254740991)}`;
    setNextNumberwang(randomNumberwang);
  }

  !nextNumberwang && getNextNumberwang();

  function NumberwangResponse() {
    let { id } = useParams();
    return(<>
      {id}?<br />
      That's not numberwang!
      <small><Link onClick={getNextNumberwang} to={nextNumberwang}>Play again?</Link></small>
    </>);
  }

  return (
    <Router>
    <div className="App">
      <header className="App-header">
        <Switch>
          <Route exact path="/">
            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={layer2} style={{ position: 'absolute' }} className="App-logo" alt="logo" />
              <img src={logo} style={{ position: 'relative' }} className={spinClass} alt="logo" />
            </div>
            <p>
              {welcomeText}
            </p>
            <a
              className="App-link"
              href={hrefString}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contactText}
            </a>
          </Route>
          <Route path="/numberwang/41">
            That's numberwang!
          </Route>
          <Route path="/numberwang/42">
            Off by one.
            <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/0obMRztklqU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <small><Link onClick={getNextNumberwang} to={nextNumberwang}>Play again?</Link></small>
          </Route>
          <Route path="/numberwang/43">
            That's numberwang!
          </Route>
          <Route path="/numberwang/:id" children={<NumberwangResponse />}/>
          <Route path="*">
            Uh oh! 4Oh4.
            <small><Link to="/">Back to Off by One</Link></small>
          </Route>
        </Switch>
      </header>
    </div>
    </Router>
  );
}

export default App;
